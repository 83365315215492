<template>
  <div class="x-wrap">
    <div class="x-page-title">
      <h3>支付记录</h3>
    </div>

    <div class="x-main">
      <!-- 筛选条件 -->
      <div class="x-q-panel">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="x-con-item">
              <label>支付单号：</label>
              <el-input
                placeholder="请输入支付单号"
                v-model="query.id"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="x-con-item">
              <label>订单编号：</label>
              <el-input
                placeholder="请输入订单编号"
                v-model="query.orderId"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="x-con-item">
              <label>提现时间：</label>
              <el-date-picker
                v-model="query.timeArr"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="x-con-item mt25">
              <label>付款方：</label>
              <el-input
                placeholder="请输入付款方"
                v-model="query.fromLike"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="x-con-item mt25">
              <label>收款方：</label>
              <el-input
                placeholder="请输入收款方"
                v-model="query.toLike"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="x-con-item mt25">
              <label>交易类型：</label>
              <el-select placeholder="请选择审核状态" v-model="query.type">
                <el-option label="充值" value="1"></el-option>
                <el-option label="提现" value="2"></el-option>
                <el-option label="支付保证金" value="3"></el-option>
                <el-option label="保证金退款" value="4"></el-option>
                <el-option label="违约金收款" value="5"></el-option>
                <el-option label="支付手续费" value="6"></el-option>
                <el-option label="藏品交易" value="7"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              class="x-q-btn mt25"
              @click="
                query.pageIndex = 1;
                doQuery();
              "
              >筛选</el-button
            >
            <el-button type="text" @click="doQueryReset">重置</el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 筛选结果 -->
      <div class="x-table-container">
        <div class="x-table-caption">
          <el-button
            icon="el-icon-refresh"
            type="primary"
            plain
            size="small"
            @click="doRefresh"
            >刷新</el-button
          >
        </div>

        <div
          class="x-table"
          v-loading="showLoading"
          element-loading-text="数据刷新中..."
        >
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="id" label="支付单号" width="120">
            </el-table-column>
            <el-table-column prop="orderId" label="订单编号" width="120">
            </el-table-column>
            <el-table-column prop="amount" label="交易金额" align="center">
            </el-table-column>
            <el-table-column prop="type" label="交易类型" align="center">
              <template slot-scope="scope">
                <span class="font-normal" v-if="scope.row.type == '1'"
                  >充值</span
                >
                <span class="font-normal" v-else-if="scope.row.type == '2'"
                  >提现</span
                >
                <span class="font-normal" v-else-if="scope.row.type == '3'"
                  >支付保证金</span
                >
                <span class="font-normal" v-else-if="scope.row.type == '4'"
                  >保证金退款</span
                >
                <span class="font-normal" v-else-if="scope.row.type == '5'"
                  >违约金收款</span
                >
                <span class="font-normal" v-else-if="scope.row.type == '6'"
                  >支付手续费</span
                >
                <span class="font-normal" v-else-if="scope.row.type == '7'"
                  >藏品交易</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="from" label="付款方"></el-table-column>
            <el-table-column prop="to" label="收款方"></el-table-column>
            <el-table-column
              prop="createdAt"
              label="交易时间"
            ></el-table-column>
          </el-table>

          <div class="x-pager right">
            <el-pagination
              background
              layout="sizes, prev, pager, next"
              :current-page.sync="query.pageIndex"
              :page-size="query.pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
              @size-change="setPageSize"
              @current-change="setPageIndex"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PaylogQuery } from "@/api/module/balance";

export default {
  name: "PaylogIndex",
  data() {
    return {
      showLoading: false,
      query: {
        type: "",
        id: "",
        orderId: "",
        fromLike: "",
        toLike: "",
        timeArr: [],
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
    };
  },

  created() {
    this.doQuery();
  },
  methods: {
    goFail(id) {
      const msg = "<span class='font-danger'>确认要驳回本条数据吗？</span>";
      this.$alert(msg, "驳回提示", {
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        confirmButtonText: "确定驳回",
        center: true,
        callback: (action) => {
          if (action === "confirm") {
            let oData = {
              id: [id],
              status: "2",
            };
            CashoutReview(oData).then((r) => {
              if (r.code === 0) {
                this.doQuery();
                this.$message({
                  type: "success",
                  message: "操作驳回成功",
                  duration: 1300,
                });
              }
            });
          }
        },
      });
    },
    async goPass(id) {
      let oData = {
        id: [id],
        status: "4",
      };
      const r = await CashoutReview(oData);
      if (r.code == 0) {
        this.$message({
          type: "success",
          message: "操作通过成功",
          duration: 1300,
        });
      }
    },
    // 查询
    doQuery() {
      let query = this.setQueryParams();
      PaylogQuery(query).then((r) => {
        this.total = r.total;
        this.tableData = r.list;
      });
    },

    // 设置筛选
    setQueryParams() {
      let query = `?pagination=true&page=${this.query.pageIndex}&pageSize=${this.query.pageSize}`;
      if (this.query.type) {
        query += `&type=${this.query.type}`;
      }
      if (this.query.id) {
        query += `&id=${this.query.id}`;
      }
      if (this.query.orderId) {
        query += `&orderId=${this.query.orderId}`;
      }
      if (this.query.fromLike) {
        query += `&fromLike=${this.query.fromLike}`;
      }
      if (this.query.toLike) {
        query += `&toLike=${this.query.toLike}`;
      }
      if (this.query.timeArr.length) {
        query += `&startTime=${this.query.timeArr[0]}&endTime=${this.query.timeArr[1]}`;
      }
      return query;
    },

    // 重置筛选
    doQueryReset() {
      this.query = {
        type: "",
        id: "",
        orderId: "",
        fromLike: "",
        toLike: "",
        timeArr: [],
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },

    // 刷新
    doRefresh() {
      this.showLoading = true;
      this.doQueryReset();
      setTimeout(() => {
        this.$message({
          type: "success",
          message: "数据更新完毕！",
          duration: 1300,
          onClose: () => {
            this.showLoading = false;
          },
        });
      }, 1350);
    },

    // 设置分页
    setPageSize(pageSize) {
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.doQuery();
    },

    // 设置页码
    setPageIndex(pageIndex) {
      this.query.pageIndex = pageIndex;
      this.doQuery();
    },
  },
};
</script>



